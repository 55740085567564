@import url('../styles/variables.css');

.contextMenu {
  position: absolute;
  background-color: var(--primary-color);
  border: var(--default-border);
  border-radius: 16px;
  box-shadow: var(--default-shadow);
  z-index: 1000;
  padding: 8px;
  margin: 0px;
  color: var(--text-color);
  max-width: 200px;
}

.contextMenu ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.contextMenuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 8px 10px;
}

.contextMenuSeparator {
  height: 1px;
  background-color: var(--border-color);
  margin: 2px;
}

.contextMenuItem p {
  overflow-wrap: break-word;
  white-space: normal;
}

.contextMenuItem.negative {
  color: var(--negative-color);
}

.contextMenuItem.positive {
  color: var(--positive-color);
}

.contextMenuItem.accent {
  color: var(--accent-color);
}

.contextMenuItem:hover {
  background-color: var(--primary-hover-color);
}

.contextMenuIcon {
  font-size: 20px;
}

.contextMenuText {
  font-size: 16px;
  margin-left: 16px;
}