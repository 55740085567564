.generalContainer {
  display: grid;
  gap: 14px;
  color: var(--text-color);
}

.memoInput {
  resize: none;
  font-size: 14px;
  height: 80px;
}

.memoOutput {
  font-size: 14px;
  min-height: 20px;
  max-height: 200px;
  text-align: left;
  margin: 0 4px;
  border: var(--default-border);
  padding: 4px;
  overflow-y: auto;
  border-radius: 4px;
}

.memoOutput h4 {
  margin: 0;
}

.memoOutput p {
  margin: 2px 0 10px 0px;
}

@media (max-width: 1451px) {
  .generalContainer {
    margin: 10px;
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: 999px) {
    .generalContainer {
      grid-template-columns: 1fr;
    }
  }
}

@media (min-width: 1450px) {
  .generalContainer {
    grid-template-columns: 1fr 300px;
    margin: 10px 15%;
  }
}