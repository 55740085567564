.statusDotContainer {
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.statusDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.statusDot.equipment[id^="1"] {
  background-color: rgb(106, 204, 60);
}

.statusDot.member[id^="2"] {
  background-color: rgb(106, 204, 60);
}

.statusDot.member[id^="3"] {
  background-color: rgb(231, 71, 71);
}

.statusDot.member[id^="4"] {
  background-color: rgb(165, 10, 10);
}

.statusDot.event[id^="1"] {
  background-color: rgb(223, 179, 83);
}

.statusDot.event[id^="2"] {
  background-color: rgb(83, 127, 223);
}

.statusDot.event[id^="3"] {
  background-color: rgb(106, 204, 60);
}

.statusDot.event[id^="4"] {
  background-color: rgb(231, 71, 71);
}

.statusDot.yesno[id^="0"] {
  background-color: rgb(106, 204, 60);
}

.statusDot.yesno[id^="1"] {
  background-color: rgb(231, 71, 71);
}