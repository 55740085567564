.generalContainer {
  display: grid;
  gap: 14px;
  color: var(--text-color);
}

.callIcon {
  border-radius: 6px;
  user-select: none;
  color: var(--positive-color);
  font-size: 20px;
  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 48;
  transition: transform 0.15s;
}

.callIcon:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.completeWaitlistForm {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
  padding: 8px;
}

@media (any-pointer: coarse) {
  .playerPresent {
    width: 20px;
    height: 20px;
  }
}

@media (any-pointer: fine) {
  .playerPresent {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1451px) {
  .generalContainer {
    margin: 10px;
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: 999px) {
    .generalContainer {
      grid-template-columns: 1fr;
    }
  }
}

@media (min-width: 1450px) {
  .generalContainer {
    grid-template-columns: 1fr 300px;
    margin: 10px 15%;
  }
}