@import url('../styles/variables.css');

@media (any-pointer: coarse) {
  .loginContainer {
    width: 100vw;
    height: 100vh;
  }

  .loginFormContainer form {
    width: 100%;
  }
}

@media (any-pointer: fine) {
  .loginContainer {
    width: 50vw;
    height: 100vh;
    position: absolute;
    transform: translateX(100%);
    border-left: var(--default-border);
  }

  .loginFormContainer form {
    width: 60%;
  }
}

.loginContainer {
  background-color: var(--primary-color);
  display: grid;
  grid-template-columns: 1;
  align-items: center;
  justify-content: center;
}

.loginFormContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.loginFormContainer .logo {
  width: 80px;
  margin-bottom: 40px;
  background-color: var(--primary-color);
  padding: 20px;
  border-radius: 10px;
}

.loginFormContainer .loginLabel {
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 40px;
}

.loginFormContainer form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginFormContainer form .textFieldGeneralContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.loginFormContainer form .forgotPassword {
  color: var(--placeholder-color);
  align-self: flex-start;
  margin-bottom: 20px;
}

.loginFormContainer form .forgotPassword:hover{
  cursor: pointer;
  text-decoration: underline;
}

.loginFormContainer form .submitContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loginFormContainer form .submitContainer .submitButton,
.loginFormContainer form .submitContainer .submitLoadingContainer {
  background-color: var(--accent-color);
  min-width: 150px;
  padding-left: 40px;
  padding-right: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  color: var(--secondary-text-color);
}

.loginFormContainer form .submitContainer .submitButton:hover {
  cursor: pointer;
  background-color: var(--accent-hover-color);
}

.loginFormContainer form .submitContainer .submitLoadingContainer {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.submitLoadingIcon {
  width: 8px;
  height: 8px;
  background-color: var(--secondary-text-color);
  border-radius: 50%;
}

.signUpLink {
  margin: 20px 0 30px 0;
  text-align: center;
  font-size: 16px;
  color: var(--secondary-placeholder-color);
  user-select: none;
}

.signUpLink a {
  color: var(--accent-color);
  text-decoration: none;
  margin-left: 4px;
}

.signUpLink a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loginError,
.loginMessage {
  font-size: 14px;
  border-radius: 10px;
  padding: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: var(--default-shadow);
}

.loginError {
  color: var(--error-text-color);
  background-color: var(--error-background-color);
  border: 1px solid var(--error-text-color);
}

.loginMessage {
  color: var(--message-text-color);
  background-color: var(--message-background-color);
  border: 1px solid var(--message-text-color);
}