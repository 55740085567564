@import url('../styles/variables.css');

.customComboBoxContainer.createform {
  background-color: var(--primary-color);
  height: 40px;
  border-radius: 10px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 4px;
  padding: 4px;
}

.customComboBoxContainer.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 4px;
  background-color: var(--primary-color);
  height: 40px;
  border: 1px solid var(--placeholder-color);
  border-radius: 6px;
  width: 100%;
}

.customComboBoxContainer p {
  margin: 0px 6px;
  color: var(--placeholder-color);
}

.customComboBoxItemContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  justify-content: stretch;
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.customComboBoxItemContainer:hover {
  cursor: pointer;
}

.customComboBoxItemContainer.deselected {
  background-color: var(--primary-color);
  transition: background-color 0.15s;
}

.customComboBoxItemContainer.deselected:hover {
  background-color: var(--primary-hover-color);
}

.customComboBoxItemContainer.selected {
  background-color: var(--primary-hover-color);
}