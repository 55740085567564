@import url('../styles/variables.css');

.generalBigContainer {
  grid-template-columns: 1fr;
}

.generalBigContainer .innerContainer {
  display: grid;
  gap: 10px;
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: var(--default-shadow);
  border: var(--default-border);
  padding: 6px;
}

.generalBigContainer .innerContainer .innerContainerLabel {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.generalBigContainer .innerContainer .generalInformationContainer,
.generalBigContainer .innerContainer .sideContainer {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 14px;
}

@media (max-width: 1450px) {
  .generalBigContainer {
    margin: 10px;
    border-radius: var();
  }

  .generalBigContainer .innerContainer {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1451px) {
  .generalBigContainer {
    margin: 10px 15%;
  }

  .generalBigContainer .innerContainer {
    grid-template-columns: 1fr 1fr;
  }
}

main {
  background-color: var(--transparent-primary-color);
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-shadow: var(--default-shadow);
  padding: 14px;
  backdrop-filter: var(--backdrop-filter-blur);
  align-self: start;
}

@media (any-pointer: coarse) {
  main {
    border-radius: var(--border-radius-mobile);
  }
  
  main.defaultMain {
    margin: 10px;
  }
}

@media (any-pointer: fine) {
  main {
    border-radius: var(--border-radius-desktop);
  }
  
  @media (max-width: 1300px) {
    main.defaultMain {
      margin: 10px;
    }
  }

  @media (min-width: 1300px) {
    main.defaultMain {
      margin: 10px 20%;
    }

    main.defaultExtendedMain {
      margin: 10px 15%;
    }

    main.extendedMain {
      margin: 0px;
    }
  }
}