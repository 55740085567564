.customToggleboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  border: var(--default-border);
  border-radius: 10px;
  height: 42px;
  padding: 0px 12px;
  box-shadow: var(--default-shadow);
  color: var(--placeholder-color);
}