.selectPlayerWrapper {
  background-color: var(--transparent-primary-color);
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-shadow: var(--default-shadow);
  padding: 14px;
  backdrop-filter: var(--backdrop-filter-blur);
  align-self: start;
  margin: 0px;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 65dvh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selectPlayerWrapper::-webkit-scrollbar {
  display: none;
}

.selectPlayerTitle {
  margin-top: 20px;
}

.eventCreateFormGroupIcon.group0 {
  color: var(--placeholder-color);
}

.eventCreateFormGroupIcon.group1 {
  color: var(--group-color-1);
}

.eventCreateFormGroupIcon.group2 {
  color: var(--group-color-2);
}

.eventCreateFormGroupIcon.group3 {
  color: var(--group-color-3);
}

.eventCreateFormGroupIcon.group4 {
  color: var(--group-color-4);
}

.eventCreateFormGroupIcon.group5 {
  color: var(--group-color-5);
}

.eventCreateFormGroupIcon.group6 {
  color: var(--group-color-6);
}

.eventCreateFormGroupIcon.group7 {
  color: var(--group-color-7);
}

.eventCreateFormGroupIcon.group8 {
  color: var(--group-color-8);
}

.eventCreateFormGroupIcon.group9 {
  color: var(--group-color-9);
}