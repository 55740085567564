@import url('../styles/variables.css');

nav {
  background-color: var(--transparent-primary-color);
  display: grid;
  grid-template-columns: 1fr 300px 1fr;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  top: 0px;
  margin-top: 10px;
  margin-bottom: 14px;
  box-shadow: var(--default-shadow);
  backdrop-filter: var(--backdrop-filter-blur);
}

.navCenterSection {
  grid-column-start: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navCenterSection .navIconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-left: 6px;
  margin-right: 6px;
  user-select: none;
}

.navRightSection {
  grid-column-start: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.navRightSection .settingsIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-left: 6px;
  margin-right: 6px;
  user-select: none;
}

.settingsIconContainer:hover,
.navIconContainer:hover {
  background-color: var(--primary-hover-color);
  cursor: pointer;
}

.navIcon,
.settingsIcon {
  color: var(--text-color);
}

.navIcon.selected {
  position: relative;
  color: var(--accent-color);
  border-radius: 3px;
  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 48
}

.navIcon.selected::after {
  content: '';
  width: 70%;
  height: 5px;
  background-color: var(--accent-color);
  display: block;
  align-self: center;
  border-radius: 3px;
  animation: navIconUnderlineOpenAnim 0.3s ease;
  left: 50%; /* Setzen Sie den linken Rand auf 50% */
  transform: translateX(-50%); /* Zentrieren Sie das Pseudoelement horizontal */
  transform-origin: 0 0; /* Ursprung am linken Rand */
  position: absolute;
  margin-top: 2px;
}

@keyframes navIconUnderlineOpenAnim {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}

.navIcon.unselected
.settingsIcon.unselected {
  color: var(--text-color);
  font-variation-settings:
  'FILL' 0,
  'wght' 600,
  'GRAD' 0,
  'opsz' 48
}

@media (any-pointer: coarse) {
  nav {
    border-radius: var(--border-radius-mobile);
    height: 80px;
  }

  .navIconContainer {
    width: 50px;
    height: 50px;
  }
  
  .navIcon {
    font-size: 30px;
  }
  
  .settingsIconContainer {
    width: 34px;
    height: 34px;
  }
  
  .settingsIcon {
    font-size: 28px;
  }
}

@media (any-pointer: fine) {
  nav {
    border-radius: var(--border-radius-desktop);
    height: 100px;
  }

  .navIconContainer {
    width: 70px;
    height: 70px;
  }
  
  .navIcon {
    font-size: 36px;
  }
  
  .settingsIconContainer {
    width: 40px;
    height: 40px;
  }
  
  .settingsIcon {
    font-size: 30px;
  }
}
  
@media (max-width: 1450px) {
  nav {
    margin: 10px 10px 14px 10px;
  }
}

@media (min-width: 1301px) {
  nav.defaultNav {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media (min-width: 1451px) {
  nav.extendedNav {
    margin-left: 15%;
    margin-right: 15%;
  }
}