body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.backgroundFixed {
  background-image: url('./images/background.jpg');
  background-size: auto 120vh;
  background-position: center center; 
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

p {
  margin: 0px;
  padding: 0px;
  user-select: none;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: default;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}