@import url('../styles/variables.css');

.nextEventContainer {
  display: grid;
  position: relative;
}

.nextEventDateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nextEventInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.nextEventInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.nextEventInfoText {
  margin-left: 10px;
  user-select: none;
}

.nextEventDateContainer .nextEventDate {
  font-size: 60px;
}

.nextEventDateContainer .nextEventMonth {
  font-size: 30px;
}

.nextEventInfoValue {
  font-size: 20px;
  font-weight: 700;
}

@media (any-pointer: coarse) {
  .nextEventContainer {
    border-radius: calc(var(--border-radius-mobile) - 6px);
  }
}

@media (any-pointer: fine) {
  .nextEventContainer {
    border-radius: calc(var(--border-radius-desktop) - 2px);
  }
}
    
@media (max-width: 850px) {
  .nextEventContainer {
    grid-template-columns: 1fr;
  }

  .nextEventDateContainer {
    border-bottom: var(--default-border);
  }

  .nextEventInfoContainer {
    align-items: center;
  }

  .nextEventInfoValue {
    margin-left: 20px;
  }

  .nextEventInfoStatusValue {
    transform: translateX(-20px);
  }
}

@media (min-width: 851px) {
  .nextEventContainer {
    grid-template-columns: 1fr 1fr;
  }

  .nextEventDateContainer {
    border-right: var(--default-border);
  }
  
  .nextEventInfoContainer {
    align-items: start;
    margin-left: 20px;
  }

  .nextEventInfoValue {
    margin-left: 60px;
  }
}