.textFieldContainer {
  background-color: var(--primary-color);
  height: 40px;
  border: 1px solid var(--placeholder-color);
  border-radius: 6px;
  width: 100%;
  display: grid;
}

.textFieldContainer.withIcon {
  grid-template-columns: 40px 1fr;
}

.textFieldContainer.withoutIcon {
  grid-template-columns: 1fr;
}

.textFieldContainer .textFieldIconContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--placeholder-color);
}

.textFieldContainer .textFieldIconContainer .textFieldIcon {
  align-self: center;
  justify-self: center;
  color: var(--text-color);
  font-size: 20px;
  user-select: none;
}

.textFieldContainer .textFieldIconContainer .textFieldIcon.filled {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}

.textFieldContainer .textFieldIconContainer .textFieldIcon.notfilled {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}

.textFieldContainer input {
  border: none;
  border-radius: 3px;
  margin: 1px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--text-color);
  background-color: var(--primary-color);
  font-size: 14px;
}

.textFieldContainer input:focus {
  outline: 3px solid var(--accent-color);
}