.listSymbolsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 4px;
  top: 4px;
  background-color: var(--primary-color);
}

.listSymbolIcon {
  user-select: none;
  font-size: 20px;
  background-color: var(--primary-color);
  border-radius: 6px;
  padding: 2px;
}

.listSymbolIcon.accent {
  color: var(--accent-color);
}

.listSymbolIcon.negative {
  color: var(--negative-color);
}