.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  user-select: none;
  color: var(--primary-light-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox.present {
  color: var(--positive-color)
}

.checkbox.notPresent {
  color: var(--negative-color)
}