.successBarContainer {
  top: 0px;
  left: 4px;
  height: 60px;
  width: 270px;
  background-color: var(--primary-color);
  position: fixed;
  display: grid;
  grid-template-columns: 70px 4fr;
  align-items: center;
  border-radius: var(--border-radius-desktop);
  box-shadow: var(--default-shadow);
}

.successBarContainer.loading {
  border: 1px solid var(--accent-color);
}

.successBarContainer.success {
  border: 1px solid var(--positive-color);
}

.loadingIcon {
  width: 32px;
  height: 32px;
  background-color: var(--accent-color);
  justify-self: center;
}

.successBarIcon {
  justify-self: center;
  width: 32px;
  height: 32px;
}

.successBarIconPath {
  stroke: var(--positive-color);
}

.successBarMessage {
  margin-left: 10px;
  margin-left: 10px;
  font-size: 14px;
  color: var(--placeholder-color);
}