.generalBigContainer .extendedMain .innerContainerTextblockEditor {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: var(--default-shadow);
  border: var(--default-border);
  padding: 14px;
}

.generalBigContainer .innerContainerTextblockEditor .innerContainerLabel {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.generalBigContainer .innerContainerTextblockEditor .textblockEditorButtonContainer {
  display: flex;
  gap: 10px;
}

.placeholderTipps {
  display: flex;
  flex-direction: row;
  gap: 6px;
  border: 1px solid var(--placeholder-color);
  padding: 10px;
  border-radius: 6px;
  background-color: var(--primary-color);
  color: var(--text-color);
  flex-wrap: wrap;
}

.placeholderTipps button {
  border: 1px solid var(--placeholder-color);
  background-color: var(--primary-light-color);
  color: var(--text-color);
  padding: 6px 16px 6px 16px;
  border-radius: 6px;
}

.placeholderTipps button:hover {
  background-color: var(--primary-light-hover-color);
  cursor: pointer;
}

.tiptap {
  font-size: 15px;
  height: 400px;
  text-align: left;
  border: 1px solid var(--placeholder-color);
  padding: 10px;
  overflow-y: auto;
  border-radius: 6px;
  user-select: none;
  background-color: var(--primary-color);
  color: var(--text-color);
}

.tiptap a {
  color: var(--accent-color);
  text-decoration: underline;
}

.bubbleMenu {
  background-color: var(--primary-light-color);
  border: 1px solid var(--primary-light-hover-color);
  border-radius: 10px;
  box-shadow: var(--default-shadow);
  display: flex;
  padding: 2px;
  gap: 2px;
}

.bubbleMenu button,
.bubbleMenu input {
  background-color: var(--primary-light-color);
  color: var(--text-color);
  border: none;
  width: 40px;
  border-radius: 8px;
}

.bubbleMenu input::-webkit-color-swatch {
  border: 1px solid var(--primary-light-hover-color);
  border-radius: 10px;
}

.bubbleMenu button:hover,
.bubbleMenu input:hover {
  background-color: var(--primary-light-hover-color);
  cursor: pointer;
}

.textblockPopupInput {
  background-color: var(--primary-color);
  color: var(--textColor);
  height: 30;
  border-radius: 10px;
  padding: 10px 6px;
  border-width: 1px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  color: var(--text-color);
}