.formContainer {
  background-color: var(--transparent-primary-color);
  box-shadow: var(--default-shadow);
  color: var(--text-color);
  backdrop-filter: var(--backdrop-filter-blur);
  z-index: 500;
  align-self: start;
  position: relative;
}

.formContainer form {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 14px;
}

.formContainer form input,
.useReferencePlayerButton {
  font-size: 15px;
  background-color: var(--primary-color);
  border: var(--default-border);
  border-radius: 10px;
  height: 42px;
  padding: 0px 12px;
  box-shadow: var(--default-shadow);
  outline: none;
  color: var(--text-color);
}

.formContainer form input::placeholder {
  color: var(--placeholder-color);
}

.dateinputContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}

.dateinputContainer input[type="date"]::-webkit-calendar-picker-indicator {
  color-scheme: var(--default-color-scheme);
}

.newError {
  font-size: 14px;
  background-color: var(--error-background-color);
  color: var(--error-text-color);
  border: 1px solid var(--error-text-color);
  border-radius: 10px;
  padding: 12px;
  margin-top: 20px;
  box-shadow: var(--default-shadow);
}

@media (any-pointer: coarse) {
  .formContainer {
    border-radius: var(--border-radius-mobile);
  }

  .dateinputContainer .datePickerIcon {
    display: none;
  }
}

@media (any-pointer: fine) {
  .formContainer {
    border-radius: var(--border-radius-desktop);
  }
}

@media (max-width: 1451px) {
  @media (max-width: 999px) {

    .formContainer {
      position: fixed;
      left: 10px;
      right: 10px;
      border: var(--default-border);
      box-shadow: var(--default-shadow);
    }

    .formOverlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
    }

    .formControls {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 50%;
      padding: 2px 6px;
      border-radius: 10px;
      background-color: var(--transparent-primary-color);
      box-shadow: var(--default-shadow);
      backdrop-filter: var(--backdrop-filter-blur);
    }

    .formControls:hover {
      cursor: pointer;
    }

    .formControlsIcon {
      color: var(--text-color);
      font-size: 30px;
      user-select: none;
    }
  }
}

@media (min-width: 1000px) {
  .formControls,
  .formOverlay {
    display: none;
  }
}