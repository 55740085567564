
.addNew {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text-color);
  border-width: 0px;
  border-radius: 25px;
  box-shadow: var(--default-shadow);
  transition: transform 0.15s;
  align-self: flex-end;
  height: 36px;
}

.addNew.positive {
  background-color: var(--positive-color);
}

.addNew.negative {
  background-color: var(--negative-color);
}

.addNew.accent {
  background-color: var(--accent-color);
}

.addNew.round {
  width: 50px;
  height: 50px;
}

.addNew.smallnormal {
  flex-grow: 1;
  max-width: 170px;
  height: 36px;
}

.addNew.smallround {
  width: 36px;
  height: 36px;
}

.addNewIcon {
  width: 100%;
  height: 100%;
}

.addNewIcon.round {
  font-size: 24px;
}

.addNewIcon.smallnormal,
.addNewIcon.smallround {
  font-size: 20px;
}