div.svgEventStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

svg.svgEventStatus {
  height: 22px;
  width: 100%;
}

svg.svgEventStatus .background {
  fill: var(--primary-hover-color);
}

svg.svgEventStatus[id^="2"] .background.filled {
  fill: rgb(126, 153, 218);
}

svg.svgEventStatus[id^="3"] .background.filled {
  fill: rgb(170, 225, 144);
}

svg.svgEventStatus .symbol {
  fill: var(--secondary-text-color);
}

svg.svgEventStatus[id^="1"] circle {
  fill: rgb(223, 179, 83);
}

svg.svgEventStatus[id^="2"] circle {
  fill: rgb(83, 127, 223);
}

svg.svgEventStatus[id^="3"] circle {
  fill: rgb(106, 204, 60);
}

svg.svgEventStatus[id^="4"] circle {
  fill: rgb(231, 71, 71);
}