.generalContainer {
  display: grid;
  gap: 14px;
  color: var(--text-color);
}

.priorityIcon {
  color: var(--negative-color);
}

@media (max-width: 1451px) {
  .generalContainer {
    margin: 10px;
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: 999px) {
    .generalContainer {
      grid-template-columns: 1fr;
    }
  }
}

@media (min-width: 1450px) {
  .generalContainer {
    grid-template-columns: 1fr 300px;
    margin: 10px 15%;
  }
}