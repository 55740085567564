@import url('../styles/variables.css');

.memoInput {
  resize: none;
  font-size: 14px;
  height: 80px;
  border-radius: 10px;
  padding: 6px;
  background-color: var(--primary-color);
  color: var(--textColor);
  border: var(--default-border);
  box-shadow: var(--default-shadow);
}

.memoInput::-webkit-scrollbar {
  background-color: var(--primary-color);
  width: 10px;
}

.memoInput::-webkit-scrollbar-thumb {
  background-color: var(--primary-hover-color);
  border-radius: 5px;
  border: 2px solid var(--primary-color);
}

h2 {
  font-size: 24px;
  margin-bottom: 18px;
  margin-top: 0;
  user-select: none;
  color: var(--text-color);
}

h3 {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 0px;
  user-select: none;
  color: var(--text-color);
}

.priceInputContainer {
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-color);
}

.priceInput {
  background-color: var(--primary-color);
  color: var(--textColor);
  height: 30;
  border-radius: 10px;
  padding: 10px 6px;
  border-width: 1px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  color: var(--text-color);
}

.textInput {
  background-color: var(--primary-color);
  color: var(--textColor);
  height: 30;
  border-radius: 10px;
  padding: 10px 6px;
  border-width: 1px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  color: var(--text-color);
}

.memoOutput {
  font-size: 14px;
  min-height: 20px;
  max-height: 200px;
  text-align: left;
  margin: 0 4px;
  border: var(--default-border);
  padding: 4px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--textColor);
}

.memoOutput h4 {
  margin: 0;
}

.memoOutput p {
  margin: 2px 0 10px 0px;
}

p.customPopupDescription {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0;
  user-select: none;
  color: var(--placeholder-color);
  font-style: italic;
  display: inline;
}

p.customPopupDescription p.customPopupLink {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0;
  color: var(--accent-color);
  display: inline;
}

p.customPopupDescription p.customPopupLink:hover {
  cursor: pointer;
}