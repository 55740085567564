:root {
  --text-color: rgb(50, 50, 50);
  --secondary-text-color: white;
  --primary-color: white;
  --primary-hover-color: rgb(235, 235, 235);
  --transparent-primary-color: rgba(255, 255, 255, 0.7);
  --accent-color: rgb(88, 155, 231);
  --accent-hover-color: rgb(119, 170, 228);
  --primary-light-color: rgb(210, 210, 210);
  --primary-light-hover-color: rgb(177, 177, 177);
  --positive-color: rgb(106, 204, 60);
  --negative-color: rgb(231, 71, 71);
  --warning-color: rgb(252, 199, 54);
  --positive-hover-color: rgb(125, 204, 89);
  --negative-hover-color: rgb(226, 106, 106);
  --warning-hover-color: rgb(247, 213, 120);
  --border-color: rgb(202, 202, 202);
  --default-border: 1px solid var(--border-color);
  --placeholder-color: rgb(148, 148, 148);
  --secondary-placeholder-color: rgb(105, 105, 105);
  --error-text-color: rgb(255, 75, 75);
  --error-background-color: rgb(255, 216, 216);
  --message-text-color: rgb(51, 178, 66);
  --message-background-color: rgb(216, 255, 222);
  --group-color-1: rgb(247, 117, 117);
  --group-color-2: rgb(179, 230, 78);
  --group-color-3: rgb(117, 232, 247);
  --group-color-4: rgb(141, 117, 247);
  --group-color-5: rgb(247, 117, 178);
  --group-color-6: rgb(247, 245, 117);
  --group-color-7: rgb(247, 173, 117);
  --group-color-8: rgb(117, 139, 247);
  --group-color-9: rgb(117, 247, 130);
  --default-color-scheme: light;

  --border-radius-desktop: 10px;
  --border-radius-mobile: 14px;
  --default-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  --defaultMarginContainer: 20%;
  --backdrop-filter-blur: blur(5px);
}

.darkTheme {
  --text-color: white;
  --secondary-text-color: white;
  --primary-color: rgb(24, 24, 26);
  --primary-hover-color: rgb(39, 39, 39);
  --transparent-primary-color: rgba(0, 0, 0, 0.7);
  --accent-color: rgb(88, 155, 231);
  --accent-hover-color: rgb(119, 170, 228);
  --primary-light-color: rgb(45, 45, 45);
  --primary-light-hover-color: rgb(92, 92, 92);
  --positive-color: rgb(106, 204, 60);
  --negative-color: rgb(231, 71, 71);
  --positive-hover-color: rgb(125, 204, 89);
  --negative-hover-color: rgb(226, 106, 106);
  --border-color: rgb(44, 44, 44);
  --default-border: 1px solid var(--border-color);
  --placeholder-color: rgb(128, 128, 128);
  --secondary-placeholder-color: rgb(136, 136, 136);
  --error-text-color: rgb(255, 75, 75);
  --error-background-color: rgb(255, 216, 216);
  --message-text-color: rgb(51, 178, 66);
  --message-background-color: rgb(216, 255, 222);
  --group-color-1: rgb(226, 120, 120);
  --group-color-2: rgb(152, 240, 120);
  --group-color-3: rgb(119, 171, 235);
  --group-color-4: rgb(217, 117, 232);
  --group-color-5: rgb(235, 180, 114);
  --group-color-6: rgb(119, 243, 232);
  --group-color-7: rgb(147, 117, 247);
  --group-color-8: rgb(247, 117, 163);
  --group-color-9: rgb(243, 247, 117);
  --default-color-scheme: dark;
}