.tooltip-container {
  display: grid;
  position: relative;
}

.tooltip {
  max-width: 300px;
  background-color: var(--primary-hover-color);
  color: var(--text-color);
  text-align: center;
  border-radius: 6px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  padding: 7px;
  position: absolute;
  z-index: 9998;
  white-space: nowrap;
  user-select: none;
  pointer-events: none;
  top: -40px;
}

@media (any-pointer: coarse) {
  .tooltip {
    display: none;
  }
}