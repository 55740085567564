@import url('../styles/variables.css');

.generalPlayerContainer .innerContainer .generalInformationContainer .buttonsIconContainer,
.generalPlayerContainer .innerContainer .sideContainer .buttonsIconContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.generalPlayerContainer .innerContainer .sideContainer .memoOutput {
  font-size: 14px;
  min-height: 50px;
  max-height: 240px;
  text-align: left;
  margin: 0 4px;
  border: var(--default-border);
  padding: 4px;
  overflow-y: auto;
  border-radius: 10px;
  color: var(--text-color);
}

.memoOutput h4 {
  margin: 0;
}

.memoOutput p {
  margin: 2px 0 10px 0px;
}

.memoInput {
  color: var(--text-color);
}