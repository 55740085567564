@import url('../styles/variables.css');

.customPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.customPopupIcon {
  margin-top: 10px;
  user-select: none;
  font-size: 60px;
  font-variation-settings:
  'FILL' 1,
  'wght' 600,
  'GRAD' 0,
  'opsz' 48;
}

.customPopupIcon.positive {
  color: var(--accent-color);
}

.customPopupIcon.negative {
  color: var(--negative-color);
}

.customPopupIcon.warning {
  color: var(--warning-color);
}

.customPopupContent {
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 16px;
  width: 300px;
  text-align: center;
  box-shadow: var(--default-shadow);
  border: var(--default-border);
  position: relative;
  max-height: 95dvh;
}

.customPopupButton {
  color: var(--secondary-text-color);
  margin: 5px;
  height: 35px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.15s;
  margin-top: 20px;
}

.customPopupButton:hover {
  cursor: pointer;
}

.customPopupButton.neutral {
  background-color: var(--primary-light-color);
}

.customPopupButton.neutral:hover {
  background-color: var(--primary-light-hover-color);
}

.customPopupButton.negative {
  background-color: var(--negative-color);
}

.customPopupButton.negative:hover {
  background-color: var(--negative-hover-color);
}

.customPopupButton.positive {
  background-color: var(--accent-color);
}

.customPopupButton.positive:hover {
  background-color: var(--accent-hover-color);
}

.customPopupButton.warning {
  background-color: var(--warning-color);
}

.customPopupButton.warning:hover {
  background-color: var(--warning-hover-color);
}

.customPopupContent h2,
.infoContainer h2 {
  font-size: 24px;
  margin-bottom: 18px;
  margin-top: 0;
  user-select: none;
}

.customPopupContent h3,
.infoContainer h3 {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 0px;
  user-select: none;
}

.customPopupContent p,
.infoContainer p {
  font-size: 16px;
  color: var(--text-color);
}

.infoContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  margin-bottom: 14px;
}