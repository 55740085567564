.generalContainer {
  display: grid;
  gap: 14px;
  color: var(--text-color);
}

.participantsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.participantsContainer p.dummyPlayers {
  color: var(--accent-color);
  margin-left: 6px;
}

.memoInput {
  resize: none;
  font-size: 14px;
  height: 80px;
}

.memoOutput {
  font-size: 14px;
  min-height: 20px;
  max-height: 200px;
  text-align: left;
  margin: 0 4px;
  border: var(--default-border);
  padding: 4px;
  overflow-y: auto;
  border-radius: 10px;
}

.memoOutput h4 {
  margin: 0;
}

.memoOutput p {
  margin: 2px 0 10px 0px;
}

.selectRoundmailContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-height: 65dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--transparent-primary-color);
  border-radius: 22px;
  padding: 10px;
  margin-top: 16px;
}

.selectRoundmailContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.selectRoundmailContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 1451px) {
  .generalContainer {
    margin: 10px;
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: 999px) {
    .generalContainer {
      grid-template-columns: 1fr;
    }
  }
}

@media (min-width: 1450px) {
  .generalContainer {
    grid-template-columns: 1fr 300px;
    margin: 10px 15%;
  }
}