.listContainer,
.listContainerHeader {
  display: grid;
  border: var(--default-border);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.15s;
  position: relative;
  background-color: var(--primary-color);
}

.listContainer.normal {
  background-color: var(--primary-color);
}

.listContainer.grayedout {
  background-color: var(--transparent-primary-color);
}

.listContainer.big,
.listContainerHeader.big {
  height: 70px;
}

.listContainer.small,
.listContainerHeader.small {
  height: 50px;
}

.listTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.listTextContainer div {
  padding: 2px 8px;
  border-radius: 6px;
  transition: background-color 0.15s;
}

.listContainer.normal .listTextContainer div {
  background-color: var(--primary-color);
}

.listTextContainer div.header.big {
  font-weight: 500;
}

.listTextContainer div.normal.big {
  font-weight: 400;
}

.listTextContainer div.header.small {
  font-weight: 400;
}

.listTextContainer div.normal.small {
  font-weight: 300;
}

.listContainerHeader div.header,
.listTextContainer div.normal {
  color: var(--text-color);
}

.listTextContainer div.accent {
  color: var(--accent-color);
}

.listTextContainer div.positive {
  color: var(--positive-hover-color);
}

.wikiListItemContainer {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border: var(--default-border);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 14px;
  transition: transform 0.15s;
}

.wikiListItemContainer.withOrderNumber {
  grid-template-columns: 80px 1fr ;
}

.wikiListItemContainer.withPriority {
  grid-template-columns: 1fr 30px;
}

.wikiListItemContainer.withOrderNumber.withPriority {
  grid-template-columns: 80px 1fr 30px;
}

.wikiListItemContainer:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.wikiListItemContainer .orderText {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.wikiListItemContainer.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 6px;
  margin-right: 10px;
}

.wikiListItemContainer .textContainer .title {
  font-size: 20px;
  font-weight: 500;
}

.wikiListItemContainer .textContainer .desc {
  font-size: 16px;
  margin-left: 10px;
  margin-top: 4px;
  color: var(--placeholder-color);
}

.wikiListItemContainer .priorityIcon {
  color: var(--negative-color);
}

.listColorExtensionContainer {
  position: absolute;
  left: -1px;
  top: 52px;
}

.listColorExtensionItem {
  position: relative;
}

.listColorExtensionItem.group1 {
  fill: var(--group-color-1);
}

.listColorExtensionItem.group2 {
  fill: var(--group-color-2);
}

.listColorExtensionItem.group3 {
  fill: var(--group-color-3);
}

.listColorExtensionItem.group4 {
  fill: var(--group-color-4);
}

.listColorExtensionItem.group5 {
  fill: var(--group-color-5);
}

.listColorExtensionItem.group6 {
  fill: var(--group-color-6);
}

.listColorExtensionItem.group7 {
  fill: var(--group-color-7);
}

.listColorExtensionItem.group8 {
  fill: var(--group-color-8);
}

.listColorExtensionItem.group9 {
  fill: var(--group-color-9);
}

@media (any-pointer: coarse) {
  .listContainer,
  .listContainerHeader {
    border-radius: calc(var(--border-radius-mobile) - 6px);
  }

  .listContainer.big,
  .listContainerHeader.big {
    font-size: 11px;
  }

  .listContainer.small,
  .listContainerHeader.small {
    font-size: 8px;
  }

  .wikiListItemContainer {
    border-radius: calc(var(--border-radius-mobile) - 6px);
  }
}

@media (any-pointer: fine) {
  .listContainer,
  .listContainerHeader {
    border-radius: calc(var(--border-radius-desktop) - 2px);
  }

  .listContainer.big,
  .listContainerHeader.big {
    font-size: 18px;
  }

  .listContainer.small,
  .listContainerHeader.small {
    font-size: 16px;
  }

  .wikiListItemContainer {
    border-radius: calc(var(--border-radius-desktop) - 2px);
  }
}