@import url('../styles/variables.css');

.generalBigContainer .innerContainer .generalInformationContainer .buttonsIconContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.generalBigContainer .innerContainer .generalInformationContainer .buttonsIconMailContainer {
  display: flex;
  gap: 10px;
}

.generalBigContainer .innerContainer .sideContainer .mailVorschau {
  font-size: 14px;
  min-height: 50px;
  max-height: 240px;
  text-align: left;
  margin: 0 4px;
  border: var(--default-border);
  padding: 4px;
  overflow-y: auto;
  border-radius: 10px;
  user-select: none;
}

.generalBigContainer .innerContainer .generalInformationContainer input[type="date"]::-webkit-calendar-picker-indicator {
  color-scheme: var(--default-color-scheme);
}

.generalBigContainer .innerContainer .sideContainer .mailVorschau {
  margin: 2px 0 10px 0px;
  color: var(--text-color);
  max-height: 400px;
}

.generalBigContainer .innerContainer .sideContainer .mailVorschau p {
  margin-bottom: 8px;
}