@import url('../styles/variables.css');

.analyticsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  max-height: 65dvh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--transparent-primary-color);
  padding-top: 10px;
  padding-bottom:  10px;
  border-radius: 22px;
}

.analyticsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.analyticsContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.analyticsCard {
  background-color: var(--primary-color);
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  border-radius: 16px;
  width: 280px;
}

.analyticsCard:hover {
  cursor: pointer;
}

.analyticsCard.closed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  position: relative;
}

.analyticsCard.open {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 360px;
}

.analyticsCard.open::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.analyticsCard.open {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.extendedAnalyticsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.extendedAnalyticsSectionContainer {
  display: grid;
  grid-template-columns: 1fr 140px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.analysticsStatsDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  margin-left: 12px;
}

.analyticsNumbers {
  margin-left: 12px;
}

.percentageText { 
  fill: var(--text-color);
  font-weight: 500;
  font-size: 16px;
}

.circularProgressBar .innerCircle {
  fill: none;
  stroke: var(--primary-hover-color);
  stroke-width: 8px;
  stroke-linecap: round;
}

.circularProgressBar .progressCircle {
  fill: none;
  stroke-width: 12px;
  stroke-linecap: round;
}

.analyticsCard.closed .analyticsIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  top: 2px;
  background-color: var(--accent-color);
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.analyticsIcon {
  color: var(--secondary-text-color);
  font-size: 22px;
  user-select: none;
}

.analyticsCard.open .analyticsTitle {
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  margin-top: 14px;
  margin-bottom: 10px;
}

.analyticsCard.closed .analyticsTitle {
  color: var(--text-color);
  font-weight: 400;
  font-size: 16px;
}