.homeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.homeContainer .bigContainer {
  background-color: var(--primary-color);
  box-shadow: var(--default-shadow);
  color: var(--text-color);
  border: var(--default-border);
  flex-basis: 100%;
  transition: transform 0.15s;
}

.homeContainer .smallContainer {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border: var(--default-border);
  box-shadow: var(--default-shadow);
  transition: transform 0.15s;
}

.bigContainer:hover,
.smallContainer:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.homeContainer .smallContainer.text {
  margin-left: 20px;
}

@media (any-pointer: coarse) {
  .homeContainer .bigContainer,
  .homeContainer .smallContainer {
    border-radius: calc(var(--border-radius-mobile) - 6px);
  }
}

@media (any-pointer: fine) {
  .homeContainer .bigContainer,
  .homeContainer .smallContainer {
    border-radius: calc(var(--border-radius-desktop) - 2px);
  }
}

@media (max-width: 850px) {
  .homeContainer .bigContainer {
    height: 400px;
  }

  .homeContainer .smallContainer {
    height: 200px;
    padding: 30px 0px;
    flex-basis: 100%;
  }

  .homeContainer .smallContainer .text,
  .homeContainer .smallContainer .icon
  {
    font-size: 26px;
  }
}

@media (min-width: 851px) {
  .homeContainer .bigContainer {
    height: 250px;
    padding: 30px 0px;
  }

  .homeContainer .smallContainer {
    height: 250px;
    padding: 30px 0px;
    flex-basis: calc(50% - 9px);
  }

  .homeContainer .smallContainer .text,
  .homeContainer .smallContainer .icon {
    font-size: 30px;
  }
}
