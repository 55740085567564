.selectPlayerWrapper {
  background-color: var(--transparent-primary-color);
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-shadow: var(--default-shadow);
  padding: 14px;
  backdrop-filter: var(--backdrop-filter-blur);
  align-self: start;
  margin: 0px;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 65dvh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selectPlayerWrapper::-webkit-scrollbar {
  display: none;
}

.selectPlayerMemberTitle {
  margin-top: 20px;
}

.selectPlayerMemberDate {
  font-size: 15px;
  background-color: var(--primary-color);
  border: var(--default-border);
  border-radius: 10px;
  height: 42px;
  padding: 0px 12px;
  box-shadow: var(--default-shadow);
  outline: none;
  color: var(--text-color);
}

.selectPlayerMemberDate::placeholder {
  color: var(--placeholder-color);
}